<template>
  <div class="id-card-upload">
    <div class="main">
      <div class="title">请上传您的身份证照片</div>
      <!-- 发给收件人按钮 -->
      <div class="rules-send-btn posi" @click="onSendBtnClick">
        <img width="17px" src="../assets/img/idCardUpload/icon_send.png" />
        <div class="rules-send-btn-text">发送给收件人填写</div>
      </div>
      <!-- 整体区域 -->
      <div class="main-div">
        <!-- 左侧 -->
        <div class="main-left">
          <!-- 条例展示 -->
          <div class="rules-show">
            <!-- 条例内容 -->
            <div class="rules-content rules-content-type-one">
              根据中国海关总署修订后的《中华人民共和国海关对进出境快件监管办法》第二十二条规定，
              入境到中国大陆的个人包裹经海关查验需向海关提供收件人身份证件（彩色）等相关信息。
            </div>
            <!-- 条例名字 -->
            <div class="rules-content-type-two rules-title">
              《中华人民共和国海关对进出境快件监管办法》海关总署令第147号
            </div>
            <!-- 查看原文 -->
            <div class="rules-online rules-content-type-two">
              前往<a
                class="rules-online-link posi"
                href="http://www.customs.gov.cn/"
                >中华人民共和国海关总署官方网站</a
              >查看原文
            </div>
            <!-- 注意事项 -->
            <div class="matters-need-attention-div">
              <div class="rules-content-type-one matters-need-attention-title">
                上传须知以及注意事项：
              </div>
              <div
                class="rules-content-type-one matters-need-attention-content"
              >
                1、请使用有效期内二代身份证，请确保所上传信息真实有效。
              </div>
              <div
                class="rules-content-type-one matters-need-attention-content"
              >
                2、所有上传的身份证信息将会被数据加密，仅作清关使用直接提交给海关进行查验。
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="main-right">
          <!-- 信息输入 -->
          <div class="main-input">
            <!-- title -->
            <img
              class="input-title"
              src="../assets/img/idCardUpload/china_title.png"
            />
            <!-- 输入内容 -->
            <div class="input-div">
              <!-- 名字 -->
              <div class="input-item">
                <div class="input-item-name">姓名：</div>
                <el-input
                  placeholder="请输入姓名"
                  maxlength="20"
                  v-model="idCardInputData.name"
                  class="input-item-value"
                  :style="{
                    '--select-borderColor': idCardInputDataCheak.name
                      ? '#3E6BA9'
                      : '#FF5353',
                  }"
                  @change="OnNameInputChange"
                ></el-input>
                <div
                  class="input-item-err-tip"
                  v-if="!idCardInputDataCheak.name"
                >
                  仅支持中文
                </div>
              </div>
              <!-- 身份证号 -->
              <div class="input-item">
                <div class="input-item-name">身份证号码：</div>
                <el-input
                  placeholder="请输入身份证号码"
                  minlength="18"
                  maxlength="18"
                  v-model="idCardInputData.idCard"
                  class="input-item-value"
                  :style="{
                    '--select-borderColor': idCardInputDataCheak.idCard
                      ? '#3E6BA9'
                      : '#FF5353',
                  }"
                  @change="OnIdCardInputChange"
                ></el-input>
                <div
                  class="input-item-err-tip"
                  v-if="!idCardInputDataCheak.idCard"
                >
                  请输入18位身份证号码
                </div>
              </div>
              <!-- 国旗页 -->
              <div class="input-item" v-if="idCardConfig.idImg">
                <div class="input-item-name">身份证人像面：</div>
                <el-upload
                  action="#"
                  :show-file-list="false"
                  accept=".jpg, .png"
                  :auto-upload="false"
                  :on-change="uploadFontImgChange"
                >
                  <div
                    class="upload-img"
                    :style="
                      idCardInputDataCheak.idImgF
                        ? 'border : 1px solid #3E6BA9'
                        : 'border : 1px solid #FF5353'
                    "
                  >
                    <img
                      width="15px"
                      v-if="!idCardInputData.idImgF.loding"
                      :src="idCardInputData.idImgF.icon"
                    />
                    <div class="loading" v-else v-loading="true"></div>
                    <div class="upload-text">
                      {{ idCardInputData.idImgF.name }}
                    </div>
                  </div>
                </el-upload>
                <div
                  :class="[
                    idCardInputDataCheak.idImgF
                      ? 'input-item-nor-tip'
                      : 'input-item-err-tip',
                  ]"
                >
                  支持JPG、PNG格式，不超过2MB
                </div>
              </div>
              <!-- 国徽页 -->
              <div class="input-item" v-if="idCardConfig.idImg">
                <div class="input-item-name">身份证国徽面：</div>
                <el-upload
                  action="#"
                  :show-file-list="false"
                  accept=".jpg, .png"
                  :auto-upload="false"
                  :on-change="uploadBehindImgChange"
                >
                  <div
                    class="upload-img"
                    :style="
                      idCardInputDataCheak.idImgH
                        ? 'border : 1px solid #3E6BA9'
                        : 'border : 1px solid #FF5353'
                    "
                  >
                    <img
                      width="15px"
                      v-if="!idCardInputData.idImgH.loding"
                      :src="idCardInputData.idImgH.icon"
                    />
                    <div class="loading" v-else v-loading="true"></div>
                    <div class="upload-text">
                      {{ idCardInputData.idImgH.name }}
                    </div>
                  </div>
                </el-upload>
                <div
                  :class="[
                    idCardInputDataCheak.idImgH
                      ? 'input-item-nor-tip'
                      : 'input-item-err-tip',
                  ]"
                >
                  支持JPG、PNG格式，不超过2MB
                </div>
              </div>
            </div>
            <!-- 提交按钮 -->
            <div class="submit-btn posi" @click="InputSubmit">提交</div>
          </div>
        </div>
      </div>
      <!-- loading -->
      <div v-if="showLoading" class="loading-div">
        <div class="content-div">
          <i class="el-icon-loading loading-text"></i>
          <span class="tip-text">身份证信息识别中</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadIdentityImage, verificationInfo } from "@/api/idCard/idCard";
export default {
  name: "IdCardUpload",
  data() {
    return {
      //身份证配置数据
      idCardConfig: {
        idNumber: true,
        idImg: true,
      },
      //输入的信息
      idCardInputData: {
        name: "",
        idCard: "",
        idImgF: {
          icon: require("../assets/img/idCardUpload/icon_upload.png"),
          name: "上传图片",
          loding: false,
          url: "",
        },
        idImgH: {
          icon: require("../assets/img/idCardUpload/icon_upload.png"),
          name: "上传图片",
          loding: false,
          url: "",
        },
      },
      //输入的信息是否错误
      idCardInputDataCheak: {
        name: true,
        idCard: true,
        idImgF: true,
        idImgH: true,
      },
      // loading开关
      showLoading: false,
    };
  },
  methods: {
    //发送给收件人填写按钮被点击
    onSendBtnClick() {
      const text = document.createElement("textarea");
      text.innerHTML = window.location.href;
      document.body.appendChild(text);
      text.select();
      document.execCommand("Copy");
      text.remove();
      this.$message.success("复制成功");
    },
    //姓名输入框发生变化
    OnNameInputChange() {
      // var reg = /^[\u4E00-\u9FFF]+$/;
      // if (!reg.test(name)) {
      //   this.idCardInputDataCheak.name = false;
      // } else {
      //   this.idCardInputDataCheak.name = true;
      // }
      this.idCardInputDataCheak.name = true;
    },
    //身份证输入框发生变化
    OnIdCardInputChange(idCard) {
      var reg =
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      if (!reg.test(idCard)) {
        this.idCardInputDataCheak.idCard = false;
      } else {
        this.idCardInputDataCheak.idCard = true;
      }
    },
    //选择人像页图片
    uploadFontImgChange(file) {
      //检验图片
      const isType =
        file.raw.type === "image/png" ||
        file.raw.type === "image/jpeg" ||
        file.raw.type === "image/jpg";
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      if (!isType) {
        this.idCardInputDataCheak.idImgF = false;
        this.$message({
          message: "图片格式错误",
          type: "error",
          duration: 2 * 1000,
        });
        return;
      }
      if (!isLt2M) {
        this.idCardInputDataCheak.idImgF = false;
        this.$message({
          message: "图片大小超出限制",
          type: "error",
          duration: 2 * 1000,
        });
        return;
      }
      this.idCardInputDataCheak.idImgF = true;
      //转为formData
      let img = file.raw;
      var form = new FormData();
      form.append("file", img);
      if (img.length != 0) {
        var i = img.length;
        this.counts = i;
        for (var j = 0; j < i; j++) {
          (function (i) {
            form.append("file", img[i]); // 文件对象
          })(j);
        }
      }
      //上传图片
      this.idCardInputData.idImgF.name = "上传中...";
      this.idCardInputData.idImgF.loding = true;
      uploadIdentityImage(form)
        .then((res) => {
          if (res.data && res.data.code == 0 && res.data.data) {
            this.$message({
              message: "上传成功",
              type: "success",
              duration: 2 * 1000,
            });
            this.idCardInputData.idImgF.name = file.name;
            this.idCardInputData.idImgF.loding = false;
            this.idCardInputData.idImgF.icon = require("../assets/img/idCardUpload/icon_pic.png");
            this.idCardInputData.idImgF.url = res.data.data.path;
          } else {
            console.log("upload fail", res);
            this.$message({
              message: res.data.message,
              type: "error",
              duration: 2 * 1000,
            });
            this.idCardInputData.idImgF.name = "上传图片";
            this.idCardInputData.idImgF.loding = false;
            this.idCardInputData.idImgF.icon = require("../assets/img/idCardUpload/icon_upload.png");
            this.idCardInputData.idImgF.url = "";
          }
        })
        .catch((err) => {
          
          console.error(err);
          this.$message({
            message: "上传失败",
            type: "error",
            duration: 2 * 1000,
          });
          this.idCardInputData.idImgF.name = "上传图片";
          this.idCardInputData.idImgF.loding = false;
          this.idCardInputData.idImgF.icon = require("../assets/img/idCardUpload/icon_upload.png");
          this.idCardInputData.idImgF.url = "";
        });
    },
    //选择国徽页图片
    uploadBehindImgChange(file) {
      //检验图片
      const isType =
        file.raw.type === "image/png" ||
        file.raw.type === "image/jpeg" ||
        file.raw.type === "image/jpg";
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(file.raw, isType, isLt2M);
      if (!isType || !isLt2M) {
        this.idCardInputDataCheak.idImgH = false;
        return;
      } else {
        this.idCardInputDataCheak.idImgH = true;
      }
      //转为formData
      let img = file.raw;
      var form = new FormData();
      form.append("file", img);
      if (img.length != 0) {
        var i = img.length;
        this.counts = i;
        for (var j = 0; j < i; j++) {
          (function (i) {
            form.append("file", img[i]); // 文件对象
          })(j);
        }
      }
      //上传图片
      this.idCardInputData.idImgH.name = "上传中...";
      this.idCardInputData.idImgH.loding = true;
      uploadIdentityImage(form)
        .then((res) => {
          if (res.data && res.data.code == 0 && res.data.data) {
            this.$message({
              message: "上传成功",
              type: "success",
              duration: 2 * 1000,
            });
            this.idCardInputData.idImgH.name = file.name;
            this.idCardInputData.idImgH.loding = false;
            this.idCardInputData.idImgH.icon = require("../assets/img/idCardUpload/icon_pic.png");
            this.idCardInputData.idImgH.url = res.data.data.path;
          } else {
            this.$message({
              message: "上传失败",
              type: "error",
              duration: 2 * 1000,
            });
            this.idCardInputData.idImgH.name = "上传图片";
            this.idCardInputData.idImgH.loding = false;
            this.idCardInputData.idImgH.icon = require("../assets/img/idCardUpload/icon_upload.png");
            this.idCardInputData.idImgH.url = "";
          }
        })
        .catch((err) => {
          console.error(err);
          this.$message({
            message: "上传失败",
            type: "error",
            duration: 2 * 1000,
          });
          this.idCardInputData.idImgH.name = "上传图片";
          this.idCardInputData.idImgH.loding = false;
          this.idCardInputData.idImgH.icon = require("../assets/img/idCardUpload/icon_upload.png");
          this.idCardInputData.idImgH.url = "";
        });
    },
    //点击了提交按钮
    InputSubmit() {
      //判断已知错误
      if (
        !this.idCardInputDataCheak.name ||
        !this.idCardInputDataCheak.idCard ||
        !this.idCardInputDataCheak.idImgF ||
        !this.idCardInputDataCheak.idImgH
      ) {
        return;
      }
      //判断数据是否为空
      if (this.idCardInputData.name == "") {
        this.idCardInputDataCheak.name = false;
        return;
      }
      if (this.idCardInputData.idCard == "") {
        this.idCardInputDataCheak.idCard = false;
        return;
      }
      //包含身份证照片
      if (this.idCardConfig.idImg) {
        if (this.idCardInputData.idImgF.url == "") {
          this.idCardInputDataCheak.idImgF = false;
          return;
        }
        if (this.idCardInputData.idImgH.url == "") {
          this.idCardInputDataCheak.idImgH = false;
          return;
        }
      }
      //数据没问题，进行提交
      let data = {
        backPath: this.idCardInputData.idImgH.url,
        frontPath: this.idCardInputData.idImgF.url,
        identityNum: this.idCardInputData.idCard.toUpperCase(),
        name: this.idCardInputData.name,
      };
      this.showLoading = true;
      verificationInfo(data)
        .then((res) => {
          this.showLoading = false;
          if (res.data && res.data.code == 0) {
            this.$message({
              message: "身份证信息验证成功",
              type: "success",
              duration: 2 * 1000,
            });
            // 清空数据
            this.idCardInputData = {
              name: "",
              idCard: "",
              idImgF: {
                icon: require("../assets/img/idCardUpload/icon_upload.png"),
                name: "上传图片",
                loding: false,
                url: "",
              },
              idImgH: {
                icon: require("../assets/img/idCardUpload/icon_upload.png"),
                name: "上传图片",
                loding: false,
                url: "",
              },
            };
          } else {
            this.$message({
              message:
                res.data.message == "" ? "验证失败" : res.data.data.messagea,
              type: "error",
              duration: 2 * 1000,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$message({
            message: "验证失败",
            type: "error",
            duration: 2 * 1000,
          });
        });
    },
  },
};
</script>
<style></style>
<style lang="less" scoped>
.id-card-upload {
  height: 100%;
  min-height: 850px;
  min-width: 1300px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // 显示区域
  .main {
    //title区域
    .title {
      height: 50px;
      font-size: 36px;
      font-weight: bold;
      color: #1e2b4f;
      line-height: 50px;
      text-align: center;
    }
    //发送按钮
    .rules-send-btn {
      width: 191px;
      height: 44px;
      background: #6d5efe;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 70px;
      margin-bottom: 20px;
      .rules-send-btn-text {
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        margin-left: 6px;
      }
    }
    //主要显示区域
    .main-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      // margin-top: 70px;
      //左侧区域
      .main-left {
        width: 600px;
        height: 606px;
        background: #ffffff;
        border-radius: 8px 0px 0px 8px;
        //条例显示区域
        .rules-show {
          margin-left: 40px;
          margin-top: 30px;
          //内容样式1
          .rules-content-type-one {
            color: #333333;
          }
          //内容样式
          .rules-content-type-two {
            color: #1e2b4f;
          }
          //条例内容
          .rules-content {
            width: 520px;
            height: 90px;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            margin-top: 20px;
          }
          //条例名字
          .rules-title {
            width: 436px;
            height: 60px;
            font-size: 20px;
            font-weight: bold;
            line-height: 30px;
            text-align: center;
            margin-left: 42px;
            margin-top: 40px;
          }
          //查看原文
          .rules-online {
            height: 22px;
            font-weight: 400;
            line-height: 22px;
            margin-top: 50px;
            .rules-online-link {
              color: #6d5efe;
            }
          }
          //注意事项模块
          .matters-need-attention-div {
            width: 520px;
            height: 180px;
            background: #f3f4fd;
            border-radius: 8px;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            padding: 20px;
            //title
            .matters-need-attention-title {
              height: 28px;
              font-size: 20px;
              font-weight: bold;
              line-height: 28px;
              margin-bottom: 10px;
            }
            .matters-need-attention-content {
              margin-top: 6px;
              height: 30px;
              font-size: 16px;
              font-weight: 400;
              line-height: 30px;
            }
          }
        }
      }
      //右侧区域
      .main-right {
        width: 600px;
        height: 606px;
        background: #16407a;
        border-radius: 0px 8px 8px 0px;
        //输入区域
        .main-input {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          //标题区域
          .input-title {
            width: 465px;
            margin-top: 50px;
          }
          //输入区域
          .input-div {
            height: 394px;
            padding-top: 51px;
            padding-bottom: 47px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            //输入框Item
            .input-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 30px;
              position: relative;
              // 输入框的作用
              .input-item-name {
                width: 112px;
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
              }
              //输入错误提示
              .input-item-err-tip {
                position: absolute;
                left: 140px;
                top: 48px;
                font-size: 14px;
                font-weight: 400;
                color: #ff5353;
                line-height: 20px;
              }
              .input-item-nor-tip {
                position: absolute;
                left: 140px;
                top: 48px;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
                opacity: 0.4;
              }
              //上传图片
              .upload-img {
                width: 286px;
                height: 44px;
                margin-left: 12px;
                background: #16407a;
                border-radius: 10px;
                border: 1px solid #3e6ba9;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-left: 16px;
                padding-right: 13px;
                .loading {
                  margin-right: 18px;
                  ::v-deep .el-loading-spinner {
                    margin-top: -8px;
                    .circular {
                      height: 17px;
                      width: 17px;
                    }
                    .path {
                      stroke-width: 3;
                      stroke: #4b8ce4;
                    }
                  }
                }
                .upload-text {
                  height: 20px;
                  font-size: 14px;
                  font-weight: bold;
                  color: #ffffff;
                  line-height: 20px;
                  margin-left: 6px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
            }
            //输入框样式修改
            .input-item::v-deep .el-input {
              width: 286px;
              margin-left: 12px;
              .el-input__inner {
                width: 286px;
                height: 44px;
                background: #16407a;
                border-radius: 10px;
                border: 1px solid var(--select-borderColor);
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                line-height: 22px;
                padding: 0 16px;
                &::placeholder {
                  font-size: 14px;
                  font-weight: 400;
                  color: #4981ce;
                  line-height: 22px;
                }
              }
            }
          }
          //提交按钮
          .submit-btn {
            width: 410px;
            height: 54px;
            background: #4b8ce4;
            border-radius: 100px;
            font-size: 20px;
            font-weight: bold;
            color: #ffffff;
            line-height: 54px;
            text-align: center;
          }
        }
      }
    }
    .loading-div {
      position: absolute;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      .content-div {
        width: 150px;
        height: 150px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tip-text {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          margin-top: 16px;
        }
        .loading-text {
          color: white;
          font-size: 30px;
        }
      }
    }
  }
}
</style>
