import http from "@/utils/http";

const resquest = `${process.env.VUE_APP_BASE_URL}`;
// const resquest = `http://192.168.1.229:7000/express`

// 上传身份证图片到云存储桶
export function uploadIdentityImage(data) {
  return http.post(`${resquest}/userInfo/identityCardInfo/uploadIdentityImage`, data);
}

// 验证身份信息
export function verificationInfo(data) {
  return http.post(`${resquest}/userInfo/identityCardInfo/verificationInfo`, data);
}

