var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"id-card-upload"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"title"},[_vm._v("请上传您的身份证照片")]),_c('div',{staticClass:"rules-send-btn posi",on:{"click":_vm.onSendBtnClick}},[_c('img',{attrs:{"width":"17px","src":require("../assets/img/idCardUpload/icon_send.png")}}),_c('div',{staticClass:"rules-send-btn-text"},[_vm._v("发送给收件人填写")])]),_c('div',{staticClass:"main-div"},[_vm._m(0),_c('div',{staticClass:"main-right"},[_c('div',{staticClass:"main-input"},[_c('img',{staticClass:"input-title",attrs:{"src":require("../assets/img/idCardUpload/china_title.png")}}),_c('div',{staticClass:"input-div"},[_c('div',{staticClass:"input-item"},[_c('div',{staticClass:"input-item-name"},[_vm._v("姓名：")]),_c('el-input',{staticClass:"input-item-value",style:({
                  '--select-borderColor': _vm.idCardInputDataCheak.name
                    ? '#3E6BA9'
                    : '#FF5353',
                }),attrs:{"placeholder":"请输入姓名","maxlength":"20"},on:{"change":_vm.OnNameInputChange},model:{value:(_vm.idCardInputData.name),callback:function ($$v) {_vm.$set(_vm.idCardInputData, "name", $$v)},expression:"idCardInputData.name"}}),(!_vm.idCardInputDataCheak.name)?_c('div',{staticClass:"input-item-err-tip"},[_vm._v(" 仅支持中文 ")]):_vm._e()],1),_c('div',{staticClass:"input-item"},[_c('div',{staticClass:"input-item-name"},[_vm._v("身份证号码：")]),_c('el-input',{staticClass:"input-item-value",style:({
                  '--select-borderColor': _vm.idCardInputDataCheak.idCard
                    ? '#3E6BA9'
                    : '#FF5353',
                }),attrs:{"placeholder":"请输入身份证号码","minlength":"18","maxlength":"18"},on:{"change":_vm.OnIdCardInputChange},model:{value:(_vm.idCardInputData.idCard),callback:function ($$v) {_vm.$set(_vm.idCardInputData, "idCard", $$v)},expression:"idCardInputData.idCard"}}),(!_vm.idCardInputDataCheak.idCard)?_c('div',{staticClass:"input-item-err-tip"},[_vm._v(" 请输入18位身份证号码 ")]):_vm._e()],1),(_vm.idCardConfig.idImg)?_c('div',{staticClass:"input-item"},[_c('div',{staticClass:"input-item-name"},[_vm._v("身份证人像面：")]),_c('el-upload',{attrs:{"action":"#","show-file-list":false,"accept":".jpg, .png","auto-upload":false,"on-change":_vm.uploadFontImgChange}},[_c('div',{staticClass:"upload-img",style:(_vm.idCardInputDataCheak.idImgF
                      ? 'border : 1px solid #3E6BA9'
                      : 'border : 1px solid #FF5353')},[(!_vm.idCardInputData.idImgF.loding)?_c('img',{attrs:{"width":"15px","src":_vm.idCardInputData.idImgF.icon}}):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"loading"}),_c('div',{staticClass:"upload-text"},[_vm._v(" "+_vm._s(_vm.idCardInputData.idImgF.name)+" ")])])]),_c('div',{class:[
                  _vm.idCardInputDataCheak.idImgF
                    ? 'input-item-nor-tip'
                    : 'input-item-err-tip' ]},[_vm._v(" 支持JPG、PNG格式，不超过2MB ")])],1):_vm._e(),(_vm.idCardConfig.idImg)?_c('div',{staticClass:"input-item"},[_c('div',{staticClass:"input-item-name"},[_vm._v("身份证国徽面：")]),_c('el-upload',{attrs:{"action":"#","show-file-list":false,"accept":".jpg, .png","auto-upload":false,"on-change":_vm.uploadBehindImgChange}},[_c('div',{staticClass:"upload-img",style:(_vm.idCardInputDataCheak.idImgH
                      ? 'border : 1px solid #3E6BA9'
                      : 'border : 1px solid #FF5353')},[(!_vm.idCardInputData.idImgH.loding)?_c('img',{attrs:{"width":"15px","src":_vm.idCardInputData.idImgH.icon}}):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"loading"}),_c('div',{staticClass:"upload-text"},[_vm._v(" "+_vm._s(_vm.idCardInputData.idImgH.name)+" ")])])]),_c('div',{class:[
                  _vm.idCardInputDataCheak.idImgH
                    ? 'input-item-nor-tip'
                    : 'input-item-err-tip' ]},[_vm._v(" 支持JPG、PNG格式，不超过2MB ")])],1):_vm._e()]),_c('div',{staticClass:"submit-btn posi",on:{"click":_vm.InputSubmit}},[_vm._v("提交")])])])]),(_vm.showLoading)?_c('div',{staticClass:"loading-div"},[_vm._m(1)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-left"},[_c('div',{staticClass:"rules-show"},[_c('div',{staticClass:"rules-content rules-content-type-one"},[_vm._v(" 根据中国海关总署修订后的《中华人民共和国海关对进出境快件监管办法》第二十二条规定， 入境到中国大陆的个人包裹经海关查验需向海关提供收件人身份证件（彩色）等相关信息。 ")]),_c('div',{staticClass:"rules-content-type-two rules-title"},[_vm._v(" 《中华人民共和国海关对进出境快件监管办法》海关总署令第147号 ")]),_c('div',{staticClass:"rules-online rules-content-type-two"},[_vm._v(" 前往"),_c('a',{staticClass:"rules-online-link posi",attrs:{"href":"http://www.customs.gov.cn/"}},[_vm._v("中华人民共和国海关总署官方网站")]),_vm._v("查看原文 ")]),_c('div',{staticClass:"matters-need-attention-div"},[_c('div',{staticClass:"rules-content-type-one matters-need-attention-title"},[_vm._v(" 上传须知以及注意事项： ")]),_c('div',{staticClass:"rules-content-type-one matters-need-attention-content"},[_vm._v(" 1、请使用有效期内二代身份证，请确保所上传信息真实有效。 ")]),_c('div',{staticClass:"rules-content-type-one matters-need-attention-content"},[_vm._v(" 2、所有上传的身份证信息将会被数据加密，仅作清关使用直接提交给海关进行查验。 ")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-div"},[_c('i',{staticClass:"el-icon-loading loading-text"}),_c('span',{staticClass:"tip-text"},[_vm._v("身份证信息识别中")])])}]

export { render, staticRenderFns }